.rbc-calendar .rbc-header,
.rbc-calendar .rbc-day-bg,
.rbc-calendar .rbc-month-view,
.rbc-calendar .rbc-month-view .rbc-header + .rbc-header,
.rbc-calendar .rbc-month-row,
.rbc-calendar .rbc-month-row + .rbc-month-row {
  border-color: transparent;
  background-color: transparent;
}
.rbc-calendar .rbc-toolbar {
  height: 50px;
  background-color: #00692c;
  position: relative;
}
.rbc-calendar .rbc-toolbar .rbc-toolbar-label {
  font-size: 20px;
  color: #fff;
  text-transform: capitalize;
}
.rbc-calendar .rbc-toolbar .rbc-btn-group:first-of-type {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rbc-calendar .rbc-toolbar .rbc-btn-group:first-of-type > button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 26px;
  width: 36px;
  height: 36px;
  border: none;
  border-radius: 50%;
  margin: 0 100px;
  padding: 0;
  cursor: pointer;
}
.rbc-calendar .rbc-toolbar .rbc-btn-group:first-of-type > button:hover {
  color: #333;
}
.rbc-calendar .rbc-toolbar .rbc-btn-group:first-of-type > button:first-of-type {
  display: none;
}
.rbc-calendar .rbc-date-cell {
  padding-left: 10px;
  color: #ccc;
  text-align: left;
}
@media screen and (min-width: 991px) {
  .rbc-calendar .rbc-date-cell {
    font-size: 25px;
    letter-spacing: -2px;
  }
}
.rbc-calendar .rbc-date-cell:not(.rbc-off-range) > a {
  cursor: default;
}
.rbc-calendar .rbc-date-cell > a:hover {
  text-decoration: none;
  color: #ccc;
}
.rbc-calendar .rbc-date-cell.rbc-now {
  color: #ea580c;
}
.rbc-calendar .rbc-date-cell.rbc-now > a:hover {
  color: #ea580c;
}
.rbc-calendar .rbc-date-cell.rbc-off-range {
  color: #f0f0f0;
}
.rbc-calendar .rbc-header {
  border-color: transparent;
  font-size: 0;
  padding-bottom: 20px;
  text-align: left;
}
.rbc-calendar .rbc-header:first-letter {
  font-size: 14px;
  color: #c2c2c2;
  border-bottom-width: 0;
  border-right-color: #eee;
  padding: 10px 12px;
  text-transform: uppercase;
  font-weight: 700;
}
.rbc-calendar .rbc-row-segment {
  padding-right: 9px;
}
.rbc-calendar button {
  font-size: 16px;
}
.rbc-overlay {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border: none;
  max-width: 350px;
}
.rbc-overlay .rbc-overlay-header {
  border: none;
  margin: 0 0 10px;
  padding: 0;
}
.rbc-overlay .rbc-event {
  margin-left: 0;
  margin-right: 0;
}
